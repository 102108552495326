import React, { FC } from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { styles } from "./Styles";
import round from "../../util/round";
import priceWithZeros from "../../util/priceWithZeros";
import formatIntWithCommas from "../../util/formatIntWithCommas";
import getBasePrice from "../../util/getBasePrice";
import SummaryDetails from "./SummaryDetails";
import { calcSuggestedRetail } from "./calcSuggestedRetail";
import letters from "../../util/letters";
import getCornerImgWidth from "../../util/getCornerImgWidth";
import noAccess from "../../util/noAccess";
import { QuoteObj, AddCostObj } from "../../GlobalTypes";
import { UnitObj } from "../../util/quoteCartStorage";
import "../fontRegister";

const localStyles = StyleSheet.create({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: "2px dashed black",
  },
  rowEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  rowStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexBasis: "36%",
  },
  unitWrap: {
    borderBottom: "1px solid grey",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 50,
    paddingTop: 20,
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  colCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 5,
  },
  unitTxt: {
    fontWeight: "bold",
    borderBottom: "1px dashed black",
    fontFamily: "Roboto-Bold",
    fontSize: 10,
  },
  unitTxt2: {
    fontWeight: "bold",
    textDecoration: "underline",
    fontFamily: "Roboto-Bold",
    fontSize: 10,
  },
  noAccess: {
    color: "red",
    fontSize: 8,
    fontFamily: "Roboto",
  },
  addonsWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexBasis: "30%",
  },
  qtyTxt: {
    alignSelf: "center",
    fontFamily: "Roboto",
    fontSize: 10,
  },
  smallTxt: {
    fontFamily: "Roboto",
    fontSize: 8,
  },
  medTxt: {
    fontFamily: "Roboto",
    fontSize: 10,
  },
  rowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    flexBasis: "12%",
  },
  priceTxt: {
    fontFamily: "Roboto-Bold",
    fontWeight: "bold",
    fontSize: 10,
  },
  dupTxt: {
    fontFamily: "Roboto",
    fontSize: 8,
    position: "absolute",
    bottom: -15,
    // right: 5,
    right: -40,
    width: 60
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexBasis: "30%",
  },
  asViewedFrom: {
    position: "absolute",
    top: 0,
    left: 20,
  },
  totals: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 30,
  },
  totals2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexBasis: "38%",
  },
  sumDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
    bottom: 30,
    right: 10,
    height: 100,
  },
  sign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute", //fixed
    left: "20%",
    bottom: -20,
    width: 300,
  },
  sumDetails2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    height: 120,
  },
});

interface SummaryPageProps {
  hidePricing: string
  data: QuoteObj
  orderedUnits: UnitObj[]
  suggestedRetail: string
  factor: number
  grandTotal: string
  codOrTerms: string
  isCustomer?: boolean
  margin: number
  withMarkup?: boolean
  addCosts?: AddCostObj[]
  salesTax: number
  customClad: boolean
  isProduction?: boolean
  deliveryMarkup?: number
  dealerTax?: number
  totalFrameItems?: number
}

const SummaryPage: FC<SummaryPageProps> = ({
  hidePricing,
  data,
  orderedUnits,
  suggestedRetail,
  factor,
  grandTotal,
  codOrTerms,
  isCustomer,
  margin,
  withMarkup,
  addCosts,
  salesTax,
  customClad,
  isProduction,
  deliveryMarkup,
  dealerTax,
  totalFrameItems
}) => {
  const customerTotal =
    isCustomer &&
    calcSuggestedRetail(suggestedRetail, margin, withMarkup || false);

  const flexBasis2 = totalFrameItems ? "13%" : "14%"

  return (
    <>
      <View style={localStyles.container}>
        <View fixed style={localStyles.rowEnd}>
          {hidePricing === "Hide Itemized Pricing" ||
          hidePricing === "Hide All Pricing" ? (
            <>
              <View style={localStyles.rowStart}>
                <Text style={[styles.text, { paddingRight: 30 }]}>
                  Quantity
                </Text>
                <Text style={[styles.text]}>Add. Options</Text>
              </View>
            </>
          ) : (
            <>
              {totalFrameItems 
              ? <Text style={[styles.text, { flexBasis: "6%" }]}>Items</Text>
              : <Text />
              }
              <Text style={[styles.text, { flexBasis: "6%" }]}>Qty.</Text>
              <Text style={[styles.text, { flexBasis: flexBasis2 }]}>
                Add. Options
              </Text>
              <Text style={[styles.text, { flexBasis: "11%" }]}>Price</Text>
            </>
          )}
        </View>

        {orderedUnits.map((door, index) => (
          <View
            wrap={false}
            key={index}
            style={[
              localStyles.unitWrap,
              { paddingBottom: orderedUnits.length - 1 === index ? 50 : 20 },
            ]}
          >
            <View style={localStyles.rowCenter}>
              <Text style={[styles.text, { paddingRight: 10 }]}>
                {letters[index]}
              </Text>

              {door.doorType.includes("Corner") ? (
                <View style={localStyles.rowCenter}>
                  <Image
                    style={{
                      height:
                        (Number(door.numberOfPanels) || 0) >= 10 ? 30 : 50,
                      width:
                        (Number(door.numberOfPanels) || 0) >= 10
                          ? getCornerImgWidth(30, door.exactConfig)
                          : getCornerImgWidth(50, door.exactConfig),
                      paddingRight: 5,
                    }}
                    src={door.exactConfigImg}
                  />
                  <Image
                    style={{
                      height:
                        (Number(door.numberOfPanels) || 0) >= 10 ? 30 : 50,
                      width:
                        (Number(door.numberOfPanels) || 0) >= 10
                          ? getCornerImgWidth(30, door.cornerConfigRight || "")
                          : getCornerImgWidth(50, door.cornerConfigRight || ""),
                    }}
                    src={door.exactConfigImgRightCorner}
                  />
                </View>
              ) : door.doorType === "Fixed Panel" ? (
                <View style={{ paddingLeft: 30, paddingRight: 40 }}>
                  <Image
                    style={{
                      height: 90,
                      width: 40,
                      marginLeft: 30,
                    }}
                    src={door.exactConfigImg}
                  />
                </View>
              ) : (
                <Image
                  style={{
                    height: door.doorType === "Bi-fold Window" ? 70 : 90,
                    width: 150,
                  }}
                  src={door.exactConfigImg}
                />
              )}

              <View style={localStyles.colCenter}>
                <Text style={styles.text}>
                  <Text style={styles.textBold}>Location: </Text>
                  {door.location}
                </Text>
                {door.doorType === "Bi-fold Zero Corner" ? (
                  <Text style={localStyles.unitTxt}>
                    Bi-fold {door.cornerType} Corner - {door.exactConfig}{" "}
                    {door.cornerConfigRight}
                  </Text>
                ) : hidePricing === "Hide All Measurements" &&
                  door.doorType !== "Swing" ? (
                  <Text style={localStyles.unitTxt}>
                    {door.doorType} - {door.exactConfig}
                  </Text>
                ) : hidePricing === "Hide All Measurements" &&
                  door.doorType === "Swing" ? (
                  <Text style={localStyles.unitTxt}>
                    {door.swingDirection} -{" "}
                    {door.exactConfig
                      .replace("Inswing", "")
                      .replace("Outswing", "")}
                  </Text>
                ) : door.doorType !== "Swing" ? (
                  <Text style={localStyles.unitTxt}>
                    {door.doorType} - {door.exactConfig} {door.unitSize}{" "}
                    {door.unitSizeFraction}" X {door.height}{" "}
                    {door.heightFraction}"
                  </Text>
                ) : (
                  <>
                    <Text style={localStyles.unitTxt}>
                      {door.swingDirection} -{" "}
                      {door.exactConfig
                        .replace("Inswing", "")
                        .replace("Outswing", "")}
                    </Text>
                    <Text style={localStyles.unitTxt2}>
                      {door.unitSize} {door.unitSizeFraction}" X {door.height}{" "}
                      {door.heightFraction}"{" "}
                      {door.doorType === "Swing" && door.equalPanels
                        ? "Equal"
                        : ""}
                    </Text>
                  </>
                )}

                {door.doorType === "Bi-fold Zero Corner" ? (
                  <Text style={localStyles.unitTxt2}>
                    L: {door.unitSize} {door.unitSizeFraction}" R:{" "}
                    {door.cornerWidthRight} {door.cornerWidthFractionRight}" X{" "}
                    {door.height} {door.heightFraction}"{" "}
                  </Text>
                ) : (
                  <Text />
                )}

                {door.doorType.includes("Corner") &&
                noAccess.includes(door.exactConfig) &&
                noAccess.includes(door.cornerConfigRight || "") ? (
                  <Text style={localStyles.noAccess}>
                    *No access from exterior
                  </Text>
                ) : door.doorType === "Bi-fold Window" ||
                  (!door.doorType.includes("Corner") &&
                    noAccess.includes(door.exactConfig)) ? (
                  <Text style={localStyles.noAccess}>
                    *No access from exterior
                  </Text>
                ) : (
                  <Text></Text>
                )}
                <Text style={styles.text}>{door.material}</Text>
                <Text style={styles.text}>
                  Wood Species -{" "}
                  <Text style={styles.textBold}>{door.woodSpecies}</Text>
                </Text>
                {door.material !== "All Wood" && (
                  <Text style={styles.text}>
                    Clad Color -{" "}
                    <Text style={styles.textBold}>
                      {door.material !== "All Wood" &&
                      door.exteriorCladColor ===
                        "Custom Color (upcharge applies)"
                        ? "Custom Color"
                        : door.material !== "All Wood" &&
                            door.exteriorCladColor &&
                            door.exteriorCladColor !==
                              "Custom Color (upcharge applies)"
                          ? door.exteriorCladColor
                          : "None"}
                    </Text>
                  </Text>
                )}
              </View>
            </View>

            {!isCustomer &&
            !isProduction &&
            (!hidePricing || hidePricing === "Hide All Measurements") ? (
              <View style={localStyles.addonsWrap}>
                {"partsItemAmount" in door && door.partsItemAmount 
                ? <Text style={localStyles.qtyTxt}>{door.partsItemAmount as number}</Text> 
                : <Text />
                }
                <Text style={localStyles.qtyTxt}>{door.quantity || "1"}</Text>

                <View style={styles.column}>
                  {door.itemizedAddons?.length ? (
                    <>
                      <Text
                        style={[localStyles.smallTxt, { paddingBottom: 5 }]}
                      >
                        Base Price:
                      </Text>
                      {door.itemizedAddons.map((item) => (
                        <Text key={item.addon} style={localStyles.smallTxt}>
                          -{item.addon}
                        </Text>
                      ))}
                    </>
                  ) : (
                    <Text></Text>
                  )}

                  <Text
                    style={[
                      localStyles.medTxt,
                      { paddingTop: door.itemizedAddons?.length ? 10 : 0 },
                    ]}
                  >
                    Unit Total:
                  </Text>
                </View>

                <View style={localStyles.rowSpace}>
                  <View style={[styles.column, { alignItems: "flex-start" }]}>
                    {door.itemizedAddons?.length ? (
                      <>
                        <Text
                          style={[
                            localStyles.smallTxt,
                            { width: 100, paddingBottom: 5 },
                          ]}
                        >
                          $
                          {getBasePrice(
                            door.itemizedAddons,
                            door.price,
                            door.quantity,
                          )}{" "}
                          {door.quantity > 1 ? "ea." : ""}
                        </Text>
                        {door.itemizedAddons.map((item) => (
                          <Text
                            key={item.price}
                            style={[localStyles.smallTxt, { width: 100 }]}
                          >
                            {item.price === 0
                              ? "Per Job"
                              : door.quantity > 1
                                ? `$${formatIntWithCommas(
                                    priceWithZeros(item.price),
                                  )} ea.`
                                : `$${formatIntWithCommas(
                                    priceWithZeros(item.price),
                                  )}`}
                          </Text>
                        ))}
                      </>
                    ) : (
                      <Text></Text>
                    )}

                    <View
                      style={[
                        styles.column,
                        {
                          alignItems: "center",
                          position: "relative",
                          paddingTop:
                            door.itemizedAddons?.length &&
                            hidePricing !== "Hide Itemized Pricing"
                              ? 10
                              : 0,
                        },
                      ]}
                    >
                      <Text
                        style={localStyles.priceTxt}
                      >{`$${door.price}`}</Text>
                      {door.quantity > 1 ? (
                        <Text
                          style={localStyles.dupTxt}
                        >{`($${priceWithZeros(door.DUPforEach as string)} ea.)`}</Text>
                      ) : (
                        <Text></Text>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            ) : (
              <>
                {hidePricing !== "Hide All Pricing" && !isProduction ? (
                  <>
                    <View style={localStyles.rowBetween}>
                      <Text style={localStyles.qtyTxt}>
                        {door.quantity || "1"}
                      </Text>

                      <View style={styles.column}>
                        {door.itemizedAddons?.length ? (
                          <>
                            {hidePricing !== "Hide Itemized Pricing" ? (
                              <Text
                                style={[
                                  localStyles.smallTxt,
                                  { paddingBottom: 5 },
                                ]}
                              >
                                Base Price:
                              </Text>
                            ) : (
                              <Text></Text>
                            )}
                            {door.itemizedAddons.map((item) => (
                              <Text
                                key={item.addon}
                                style={localStyles.smallTxt}
                              >
                                -{item.addon}
                              </Text>
                            ))}
                          </>
                        ) : (
                          <Text></Text>
                        )}

                        <Text
                          style={[
                            localStyles.medTxt,
                            {
                              paddingTop: door.itemizedAddons?.length ? 10 : 0,
                            },
                          ]}
                        >
                          Unit Total:
                        </Text>
                      </View>

                      <View style={localStyles.rowSpace}>
                        <View
                          style={[styles.column, { alignItems: "flex-start" }]}
                        >
                          {hidePricing !== "Hide Itemized Pricing" &&
                          door.itemizedAddons?.length ? (
                            <>
                              {hidePricing !== "Hide Itemized Pricing" ? (
                                <Text
                                  style={[
                                    localStyles.smallTxt,
                                    { width: 100, paddingBottom: 5 },
                                  ]}
                                >
                                  $
                                  {getBasePrice(
                                    door.itemizedAddons,
                                    door.price,
                                    door.quantity,
                                    margin,
                                    withMarkup,
                                  )}{" "}
                                  {door.quantity > 1 ? "ea." : ""}
                                </Text>
                              ) : (
                                <Text></Text>
                              )}
                              {door.itemizedAddons.map((item) => (
                                <Text
                                  key={item.price}
                                  style={[localStyles.smallTxt, { width: 100 }]}
                                >
                                  {item.price === 0
                                    ? "Per Job"
                                    : door.quantity > 1 && margin && !withMarkup
                                      ? `$${formatIntWithCommas(
                                          priceWithZeros(
                                            round(item.price / margin),
                                          ),
                                        )} ea.`
                                      : item.price === 0
                                        ? "Per Job"
                                        : door.quantity > 1 &&
                                            margin &&
                                            withMarkup
                                          ? `$${formatIntWithCommas(
                                              priceWithZeros(
                                                round(
                                                  item.price * (1 - margin + 1),
                                                ),
                                              ),
                                            )} ea.`
                                          : door.quantity <= 1 &&
                                              margin &&
                                              !withMarkup
                                            ? `$${formatIntWithCommas(
                                                priceWithZeros(
                                                  round(item.price / margin),
                                                ),
                                              )}`
                                            : door.quantity <= 1 &&
                                                margin &&
                                                withMarkup
                                              ? `$${formatIntWithCommas(
                                                  priceWithZeros(
                                                    round(
                                                      item.price *
                                                        (1 - margin + 1),
                                                    ),
                                                  ),
                                                )}`
                                              : door.quantity > 1 && !margin
                                                ? `$${formatIntWithCommas(
                                                    priceWithZeros(item.price),
                                                  )} ea.`
                                                : door.quantity <= 1 && !margin
                                                  ? `$${formatIntWithCommas(
                                                      priceWithZeros(
                                                        item.price,
                                                      ),
                                                    )}`
                                                  : ""}
                                </Text>
                              ))}
                            </>
                          ) : (
                            <Text></Text>
                          )}

                          <View
                            style={[
                              styles.column,
                              {
                                alignItems: "center",
                                position: "relative",
                                paddingTop:
                                  door.itemizedAddons?.length &&
                                  hidePricing !== "Hide Itemized Pricing"
                                    ? 10
                                    : door.itemizedAddons?.length &&
                                        hidePricing === "Hide Itemized Pricing"
                                      ? 20
                                      : 0,
                              },
                            ]}
                          >
                            <Text style={localStyles.priceTxt}>
                              {margin && !withMarkup
                                ? `$${formatIntWithCommas(
                                    priceWithZeros(
                                      round(
                                        Number(door.customerUnitPrice) / margin,
                                      ),
                                    ),
                                  )}`
                                : margin && withMarkup
                                  ? `$${formatIntWithCommas(
                                      priceWithZeros(
                                        round(
                                          Number(door.customerUnitPrice) *
                                            (1 - margin + 1),
                                        ),
                                      ),
                                    )}`
                                  : `$${formatIntWithCommas(
                                      priceWithZeros(
                                        door.customerUnitPrice?.toString() ||
                                          "",
                                      ),
                                    )}`}
                            </Text>
                            {door.quantity > 1 && margin && !withMarkup ? (
                              <Text style={localStyles.dupTxt}>
                                {`($${formatIntWithCommas(
                                  priceWithZeros(
                                    round(
                                      Number(door.customerUnitPrice) /
                                        margin /
                                        door.quantity,
                                    ),
                                  ),
                                )} ea.)`}
                              </Text>
                            ) : door.quantity > 1 && margin && withMarkup ? (
                              <Text style={localStyles.dupTxt}>
                                {`($${formatIntWithCommas(
                                  priceWithZeros(
                                    round(
                                      (Number(door.customerUnitPrice) *
                                        (1 - margin + 1)) /
                                        door.quantity,
                                    ),
                                  ),
                                )} ea.)`}
                              </Text>
                            ) : (
                              <Text></Text>
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <>
                    <View style={localStyles.rowBetween}>
                      <Text style={localStyles.medTxt}>
                        {door.quantity || "1"}
                      </Text>

                      <View
                        style={[
                          styles.column,
                          { alignItems: "flex-start", width: 100 },
                        ]}
                      >
                        {door.itemizedAddons?.length ? (
                          door.itemizedAddons.map((item) => (
                            <Text key={item.addon} style={localStyles.smallTxt}>
                              -{item.addon}
                            </Text>
                          ))
                        ) : (
                          <Text></Text>
                        )}
                      </View>
                    </View>
                  </>
                )}
              </>
            )}
          </View>
        ))}
        <Text fixed style={[styles.text, localStyles.asViewedFrom]}>
          (* As Viewed From Exterior)
        </Text>

        <View style={localStyles.totals}>
          <Text style={styles.text}>Totals:</Text>
          <Text style={styles.text}>Panels: {data.totalPanels || ""}</Text>

          {totalFrameItems 
            ? <Text style={styles.text}>Items: {totalFrameItems || 0}</Text> 
            : <Text />
          }

          <View style={localStyles.totals2}>
            <Text style={[styles.text, { borderTop: "1px solid" }]}>
              Units: {data.totalUnits || ""}
            </Text>
            {hidePricing !== "Hide All Pricing" ||
            (!isCustomer && !isProduction) ? (
              <Text style={[styles.textBold, { borderTop: "1px solid black" }]}>
                ${isCustomer ? customerTotal : suggestedRetail}
              </Text>
            ) : (
              <Text></Text>
            )}
          </View>
        </View>
      </View>

      {orderedUnits.length < 5 ? (
        <>
          {hidePricing !== "Hide All Pricing" ||
          (!isCustomer && !isProduction) ? (
            <View style={localStyles.sumDetails}>
              <SummaryDetails
                isCustomer={isCustomer}
                data={data}
                orderedUnits={orderedUnits}
                suggestedRetail={suggestedRetail}
                factor={factor}
                codOrTerms={codOrTerms}
                grandTotal={grandTotal}
                addCosts={addCosts || []}
                salesTax={salesTax}
                margin={margin}
                withMarkup={withMarkup}
                customClad={customClad}
                deliveryMarkup={deliveryMarkup || 0}
                dealerTax={dealerTax || null}
              />
            </View>
          ) : isProduction ? (
            <Text></Text>
          ) : (
            <View style={localStyles.sign}>
              <Text style={styles.textBold}>
                Signature: ____________________________________
              </Text>
              <Text style={styles.text}>Date: ____________</Text>
            </View>
          )}
        </>
      ) : (
        <>
          {hidePricing !== "Hide All Pricing" ||
          (!isCustomer && !isProduction) ? (
            <View wrap={false} style={localStyles.sumDetails2}>
              <SummaryDetails
                isCustomer={isCustomer}
                data={data}
                orderedUnits={orderedUnits}
                suggestedRetail={suggestedRetail}
                factor={factor}
                codOrTerms={codOrTerms}
                grandTotal={grandTotal}
                addCosts={addCosts || []}
                salesTax={salesTax}
                margin={margin}
                withMarkup={withMarkup}
                customClad={customClad}
                deliveryMarkup={deliveryMarkup || 0}
                dealerTax={dealerTax || null}
              />
            </View>
          ) : isProduction ? (
            <Text></Text>
          ) : (
            <View style={localStyles.sign}>
              <Text style={styles.textBold}>
                Signature: ____________________________________
              </Text>
              <Text style={styles.text}>Date: ____________</Text>
            </View>
          )}
        </>
      )}
    </>
  );
};

export default SummaryPage;
