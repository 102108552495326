import letters from "../../util/letters";
import {
  bifoldKdWeights,
  sliderKdWeights,
  fixedPanelKdWeights,
} from "./findKdWeights";
import fractionToInt from "../../util/fractionToInt";
import { UnitObj } from "../../util/quoteCartStorage";

export interface LabelArrayItm {
  id?: string,
  location?: string
  configuration?: string
  frameWeight?: string
  panelWeight?: string
  doorType?: string
  kd?: boolean
  series?: string
  primaryDoor?: string
  numPanelsLeft?: number
  sideliteSide?: string | null
  headJambWeight?: number
  sillWeight?: number
  jambLegWeight?: number
  item?: string
  description?: string
  unit?: string
}

function findShipLabelData(
  units: UnitObj[],
  isCustom: boolean,
  seriesOverride: string,
) {
  var labelData: LabelArrayItm[] = [];
  var totalItems = 0;

  units.forEach((unit) => {
    const idx = units.indexOf(unit);

    const unitLetter = letters[idx];

    var totalUnitItems = !unit.kd
      ? Number(unit.numberOfPanels) + 2
      : unit.doorType.includes("Corner")
        ? Number(unit.numberOfPanels) + 7
        : Number(unit.numberOfPanels) + 5;

    if (unit.kd && unit.addScreen) {
      if (unit.addScreen.includes("Double")) totalUnitItems += 2;
      else totalUnitItems++;

      if (unit.doorType === "Slider" && unit.sliderPocket === "With Pocket") {
        if (unit.exactConfig.includes("-")) totalUnitItems += 2;
        else totalUnitItems++;
      }
    }

    if (unit.doorType === "Slider") {
      if (unit.sillStyle?.includes("Steel")) totalUnitItems++;

      if (unit.sliderPocket === "With Pocket") {
        if (unit.exactConfig.includes("-")) totalUnitItems += 6;
        else totalUnitItems += 3;
      } else {
        if (unit.addSlidingScreen && unit.addSlidingScreen.includes("Double"))
          totalUnitItems += 2;
        else if (unit.addSlidingScreen) totalUnitItems++;
      }
    }

    const panelWidth =
      !isCustom &&
      unit.doorType !== "Swing" &&
      unit.doorType !== "Pivot" &&
      unit.panelWidth
        ? fractionToInt(
            unit.panelWidth.split(" ")[0] || "",
            unit.panelWidth.split(" ")[1] || "",
          )
        : fractionToInt(
            unit.swingPanelWidth || "",
            unit.swingPanelFraction || "",
          );

    let label: Partial<LabelArrayItm> = {
      id: unit._id || "",
      location: (unit.location || "N/A") as string,
      configuration: unit.exactConfig,
      frameWeight: unit.frameWeight || "",
      panelWeight: unit.perPanelWeight || "",
      doorType: unit.doorType,
      kd: unit.kd || false,
      series: !unit.doorType.includes("Bi-fold")
        ? ""
        : isCustom && "series" in unit
          ? (unit.series as string)
          : seriesOverride && seriesOverride !== "Default"
            ? seriesOverride
            : panelWidth >= 42
              ? "Series 2"
              : "Series 1",
    };

    let labelRight: Partial<LabelArrayItm> = {};

    if (unit.doorType.includes("Corner")) {
      const numPanelsLeft = Number(unit.exactConfig.split("")[0]);
      const panelWidthRight = !isCustom
        ? fractionToInt(
            unit.panelWidthRightCorner?.split(" ")[0] || "",
            unit.panelWidthRightCorner?.split(" ")[1] || "",
          )
        : 0;

      const seriesCorner =
        isCustom && "series" in unit && unit.series
          ? (unit.series as string)
          : seriesOverride && seriesOverride !== "Default"
            ? seriesOverride
            : panelWidth >= 42 || panelWidthRight >= 42
              ? "Series 2"
              : "Series 1";

      label = {
        ...label,
        primaryDoor: unit.primaryDoor || "",
        numPanelsLeft: numPanelsLeft,
        series: seriesCorner,
      };

      labelRight = {
        id: unit._id || "",
        location: unit.location || "N/A",
        configuration: unit.cornerConfigRight || "",
        frameWeight: unit.frameWeight || "",
        panelWeight: unit.perPanelWeightRightCorner || "",
        doorType: unit.doorType,
        kd: unit.kd || false,
        primaryDoor: unit.primaryDoor || "",
        numPanelsLeft: numPanelsLeft,
        series: seriesCorner,
      };
    }

    if (
      (unit.doorType === "Swing" || unit.doorType === "Pivot") &&
      unit.sideLite !== "none"
    ) {
      if (isCustom) {
        label = {
          ...label,
          sideliteSide: unit.exactConfig.slice(-4) === "Lock" ? "LOCK" : "",
        };
      } else {
        label = {
          ...label,
          sideliteSide: unit.exactConfigImg?.includes("LOCK") ? "LOCK" : null,
        };
      }
    }

    if (unit.doorType === "Slider" && unit.kd && !isCustom) {
      const netWidth =
        unit.sliderPocket === "With Pocket" && unit.pocketingSliderNetWidth
          ? fractionToInt(
              unit.pocketingSliderNetWidth.split(" ")[0],
              unit.pocketingSliderNetWidth.split(" ")[1] || "",
            )
          : fractionToInt(unit.unitSize, unit.unitSizeFraction || "");

      const netHeight = fractionToInt(unit.height, unit.heightFraction || "");

      const { headJamb, sill, legJamb } = sliderKdWeights(
        netWidth,
        netHeight,
        unit.exactConfig,
        unit.sliderPocket === "With Pocket",
        unit.sillStyle === "Stainless Steel Recessed *",
        unit.addScreen ? true : false,
        unit.addSlidingScreen ? true : false,
      );

      label = {
        ...label,
        headJambWeight: headJamb,
        sillWeight: sill,
        jambLegWeight: legJamb,
      };
    } else if (unit.doorType.includes("Corner") && unit.kd && !isCustom) {
      const netHeight = fractionToInt(unit.height, unit.heightFraction || "");

      const leftKd = bifoldKdWeights(
        Number(unit.cornerFrameWidthLeft) || 0,
        netHeight,
        unit.addScreen ? true : false,
        unit.sillStyle || "",
      );

      const rightKd = bifoldKdWeights(
        Number(unit.cornerFrameWidthRight) || 0,
        netHeight,
        unit.addScreen ? true : false,
        unit.sillStyle || "",
      );

      label = {
        ...label,
        headJambWeight: leftKd.headJamb,
        sillWeight: leftKd.sill,
        jambLegWeight: leftKd.legJamb,
      };

      labelRight = {
        ...labelRight,
        headJambWeight: rightKd.headJamb,
        sillWeight: rightKd.sill,
        jambLegWeight: rightKd.legJamb,
      };
    } else if (unit.doorType.includes("Bi-fold") && unit.kd && !isCustom) {
      const netWidth = fractionToInt(
        unit.unitSize,
        unit.unitSizeFraction || "",
      );
      const netHeight = fractionToInt(unit.height, unit.heightFraction || "");

      const { headJamb, sill, legJamb } = bifoldKdWeights(
        netWidth,
        netHeight,
        unit.addScreen ? true : false,
        unit.sillStyle || "",
      );

      label = {
        ...label,
        headJambWeight: headJamb,
        sillWeight: sill,
        jambLegWeight: legJamb,
      };
    } else if (unit.doorType === "Fixed Panel" && unit.kd && !isCustom) {
      const netWidth = fractionToInt(
        unit.unitSize,
        unit.unitSizeFraction || "",
      );
      const netHeight = fractionToInt(unit.height, unit.heightFraction || "");

      const { headJamb, sill, legJamb } = fixedPanelKdWeights(
        unit.exactConfig,
        netWidth,
        netHeight,
      );

      label = {
        ...label,
        headJambWeight: headJamb,
        sillWeight: sill,
        jambLegWeight: legJamb,
      };
    }

    function addLabel(labelNum: number, description: string) {
      return labelData.push({
        ...label,
        item: `${labelNum} of ${totalUnitItems}`,
        description,
      });
    }

    function addLabelRight(labelNum: number, description: string) {
      return labelData.push({
        ...labelRight,
        item: `${labelNum} of ${totalUnitItems}`,
        description,
      });
    }

    for (let j = 0; j < unit.quantity; j++) {
      label = {
        ...label,
        unit: unit.quantity === 1 ? unitLetter : `${unitLetter} (${j + 1})`,
      };
      let itmNum = 0;

      if (unit.doorType.includes("Corner")) {
        const numPanelsLeft = Number(unit.exactConfig.split("")[0]);

        labelRight = {
          ...labelRight,
          unit: unit.quantity === 1 ? unitLetter : `${unitLetter} (${j + 1})`,
        };

        for (let i = 0; i < Number(unit.numberOfPanels); i++) {
          const description = `Door: ${i + 1}`;

          if (i < numPanelsLeft) {
            addLabel(i + 1, description);
            addLabel(i + 1, description);
          } else {
            addLabelRight(i + 1, description);
            addLabelRight(i + 1, description);
          }
          itmNum += 1;
        }
      } else {
        for (let i = 0; i < Number(unit.numberOfPanels); i++) {
          const description = `Door: ${i + 1}`;
          addLabel(i + 1, description);
          addLabel(i + 1, description);
          itmNum += 1;
        }
      }

      if (unit.kd) {
        if (unit.doorType.includes("Corner")) {
          itmNum++;
          addLabel(itmNum, "Left Head Jamb");

          itmNum++;
          addLabel(itmNum, "Left Sill");

          itmNum++;
          addLabel(itmNum, "Left Jamb Leg");

          itmNum++;
          addLabel(itmNum, "Right Head Jamb");

          itmNum++;
          addLabel(itmNum, "Right Sill");

          itmNum++;
          addLabel(itmNum, "Right Jamb Leg");
        } else {
          itmNum++;
          addLabel(itmNum, "Head Jamb");

          itmNum++;
          addLabel(itmNum, "Sill");

          itmNum++;
          addLabel(itmNum, "Left Jamb Leg");

          itmNum++;
          addLabel(itmNum, "Right Jamb Leg");
        }

        if (unit.addScreen) {
          itmNum++;
          addLabel(itmNum, "Retractable Screen");

          if (unit.addScreen.includes("Double")) {
            itmNum++;
            addLabel(itmNum, "Retractable Screen");
          }

          if (
            unit.doorType === "Slider" &&
            unit.sliderPocket === "With Pocket"
          ) {
            if (unit.exactConfig.includes("-")) {
              itmNum++;
              addLabel(itmNum, "Screen Pocket");
              itmNum++;
              addLabel(itmNum, "Screen Pocket");
            } else {
              itmNum++;
              addLabel(itmNum, "Screen Pocket");
            }
          }
        }
      } else {
        itmNum++;
        addLabel(itmNum, "Frame");
      }

      if (unit.doorType === "Slider") {
        if (unit.sillStyle?.includes("Steel")) {
          itmNum++;
          addLabel(itmNum, "Stainless Steel Track");
        }

        if (unit.sliderPocket === "With Pocket") {
          const side = unit.exactConfig.includes("-")
            ? "both"
            : unit.exactConfig[0] === "P"
              ? "left"
              : "right";

          switch (side) {
            case "both":
              itmNum++;
              addLabel(itmNum, "Exterior Pocket Stop Left");
              itmNum++;
              addLabel(itmNum, "Interior Pocket Stop Left");
              itmNum++;
              addLabel(itmNum, "Left Concealer");
              itmNum++;
              addLabel(itmNum, "Exterior Pocket Stop Right");
              itmNum++;
              addLabel(itmNum, "Interior Pocket Stop Right");
              itmNum++;
              addLabel(itmNum, "Right Concealer");
              break;
            case "left":
              itmNum++;
              addLabel(itmNum, "Exterior Pocket Stop Left");
              itmNum++;
              addLabel(itmNum, "Interior Pocket Stop Left");
              itmNum++;
              addLabel(itmNum, "Left Concealer");
              break;
            case "right":
              itmNum++;
              addLabel(itmNum, "Exterior Pocket Stop Right");
              itmNum++;
              addLabel(itmNum, "Interior Pocket Stop Right");
              itmNum++;
              addLabel(itmNum, "Right Concealer");
              break;
            default:
              console.log("default switch");
          }
        } else if (unit.addSlidingScreen) {
          itmNum++;
          addLabel(itmNum, "Sliding Screen");

          if (unit.addSlidingScreen.includes("Double")) {
            itmNum++;
            addLabel(itmNum, "Sliding Screen");
          }
        }
      }

      itmNum++;
      addLabel(itmNum, "Parts Box");

      totalItems += itmNum;
    }
  });

  return { labelData, totalItems };
}

export default findShipLabelData;
