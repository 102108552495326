import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import { Fade, Alert, Toolbar, Typography, Button } from "@mui/material";
import GlassOrderModal from "./Glass/GlassOrderModal";
import ExportBtn from "./Export/ExportBtn";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { getLabelData } from "../../../api/DzoneApi";
import QRCode from "qrcode";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { styled } from "@mui/material/styles";
import { QuoteObj } from "../../../GlobalTypes";

const GenerateOrderSheet = lazy(
  () => import("../../../quotePDF/OrderSheet/OrderSheet"),
);

const GenerateLabels = lazy(
  () => import("../../../quotePDF/Labels/GenerateLabels"),
);

const GenerateShipLabels = lazy(
  () => import("../../../quotePDF/ShipLabels/GenerateShipLabels"),
);

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  color: "#4b4b4b",
}));

const StyledDivSpaceBtw = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
}));

const StyledDivEndRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "300%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    width: "100%",
  },
}));

const StyledDivEndRow2 = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
}));

const StyledDivUncheck = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
}));

const StyledUncheckButton = styled("button")(({ theme }) => ({
  borderRadius: 5,
  width: 160,
  [theme.breakpoints.down("sm")]: {
    width: 100,
  },
}));

interface ToolbarProps {
  showTab: string;
  numSelected: number;
  handleOpenModal?: () => void;
  handleOpen?: (type: string, showWarningModal?: boolean) => void;
  quote?: QuoteObj[];
  unCheckAll: () => void;
  searchTerm: string;
  searchTermFilter: string;
  isSearch?: boolean;
  openDuplicateModal?: () => void;
  handleOpenModalArchive?: () => void;
  showArchive?: string;
  handleOpenApt?: () => void;
  handleOpenInactiveModal?: () => void;
  showActive?: string;
  setCopy?: Dispatch<SetStateAction<boolean>>;
  clipData?: string;
  copy?: boolean;
  showEditBtn?: () => boolean;
  handleOpenCustomerListModal?: () => void;
}

const TableToolbar: FC<ToolbarProps> = ({
  showTab,
  numSelected,
  handleOpenModal,
  handleOpen,
  quote,
  unCheckAll,
  searchTerm,
  searchTermFilter,
  isSearch,
  openDuplicateModal,
  handleOpenModalArchive,
  showArchive,
  handleOpenApt,
  handleOpenInactiveModal,
  showActive,
  setCopy,
  clipData,
  copy,
  showEditBtn,
  handleOpenCustomerListModal,
}) => {
  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const [viewPDF, setViewPDF] = useState(false);

  const [labelData, setLabelData] = useState([]);

  const [qrCode, setQrCode] = useState("");

  const [viewPDFLabels, setViewPDFLabels] = useState(false);

  const [viewShipLabels, setViewShipLabels] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpenGlass = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setViewPDF(false);
    setViewPDFLabels(false);
    setViewShipLabels(false);
    setQrCode("");
  }, [numSelected]);

  const handleClick = () => setViewPDF(true);

  const handleClickShipLabels = async () => {
    if (!quote || !quote[0] || role !== "Admin") return;

    const url =
      process.env.NODE_ENV === "production"
        ? `https://agdealerzone.com/installDetails/${quote[0]._id}`
        : `http://192.168.1.124:3000/installDetails/${quote[0]._id}`;

    try {
      const qrSrc = await QRCode.toDataURL(url);
      setQrCode(qrSrc);
      setViewShipLabels(true);
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const handleClickLabels = async (salesOrder: string) => {
    const response = await getLabelData(salesOrder);
    if (!response?.data?.data) return;

    setLabelData(response.data.data);
    setViewPDFLabels(true);
  };

  return (
    <>
      {(showTab === "Quotes" || showTab === "Orders") && (
        <GlassOrderModal open={open} handleClose={handleClose} quote={quote} />
      )}

      <StyledToolbar
        style={{ backgroundColor: numSelected > 0 ? "#57d5f2" : "" }}
      >
        {numSelected > 0 && (
          <StyledDivUncheck>
            <Typography
              style={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
            <StyledUncheckButton onClick={unCheckAll}>
              Un-Check All
            </StyledUncheckButton>
          </StyledDivUncheck>
        )}

        <StyledDivSpaceBtw>
          <StyledDivEndRow>
            <StyledDivEndRow2>
              {showTab === "Customer List" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <Fade in={copy} timeout={1000}>
                    <Alert style={{ marginRight: 20 }} severity="success">
                      Copied to Clipboard!
                    </Alert>
                  </Fade>

                  <CopyToClipboard
                    text={clipData || ""}
                    onCopy={() => setCopy && setCopy(true)}
                  >
                    <Button id="copyBtn" variant="contained" size="small">
                      Copy Emails
                    </Button>
                  </CopyToClipboard>
                </div>
              )}

              {numSelected === 1 && showTab === "Orders" && quote?.length && (
                <>
                  {quote[0]?.salesOrder && quote[0]?.salesOrder !== "N/A" && (
                    <>
                      {role === "Admin" && (
                        <div style={{ padding: 5 }}>
                          {!viewShipLabels ? (
                            <Button
                              size="small"
                              style={{ fontSize: 11, color: "white" }}
                              variant="contained"
                              color="primary"
                              onClick={handleClickShipLabels}
                            >
                              Shipping Labels
                            </Button>
                          ) : (
                            <Suspense
                              fallback={
                                <Button
                                  size="small"
                                  style={{ fontSize: 11, color: "black" }}
                                  variant="contained"
                                >
                                  Loading...
                                </Button>
                              }
                            >
                              <GenerateShipLabels
                                data={quote[0]}
                                qrCode={qrCode}
                              />
                            </Suspense>
                          )}
                        </div>
                      )}

                      <div style={{ padding: 5 }}>
                        {!viewPDFLabels ? (
                          <Button
                            size="small"
                            style={{ fontSize: 11, color: "white" }}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleClickLabels(quote[0]?.salesOrder || "")
                            }
                          >
                            CNC Labels
                          </Button>
                        ) : viewPDFLabels && labelData.length ? (
                          <Suspense
                            fallback={
                              <Button
                                size="small"
                                style={{ fontSize: 11, color: "black" }}
                                variant="contained"
                                color="inherit"
                              >
                                Loading...
                              </Button>
                            }
                          >
                            <GenerateLabels
                              unitLabels={labelData}
                              salesOrder={quote[0].salesOrder}
                            />
                          </Suspense>
                        ) : null}
                      </div>

                      <div style={{ padding: 5 }}>
                        {!viewPDF ? (
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            style={{ color: "white", fontSize: 11 }}
                            onClick={handleClick}
                          >
                            Generate Order Sheet
                          </Button>
                        ) : (
                          <Suspense
                            fallback={
                              <Button
                                size="small"
                                style={{ color: "black", fontSize: 11 }}
                                variant="contained"
                                color="inherit"
                              >
                                Loading...
                              </Button>
                            }
                          >
                            <GenerateOrderSheet data={quote ? quote[0] : {}} />
                          </Suspense>
                        )}
                      </div>
                    </>
                  )}

                  {quote && quote[0].status === "Awaiting Deposit" && (
                    <div style={{ padding: 5 }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        style={{ color: "white", fontSize: 11 }}
                        onClick={() => handleOpen && handleOpen("Deposit")}
                      >
                        Add Date of Deposit
                      </Button>
                    </div>
                  )}

                  <div style={{ padding: 5 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ color: "white", fontSize: 11 }}
                      onClick={() =>
                        !handleOpen
                          ? null
                          : quote && !quote[0].salesOrder
                            ? handleOpen("Sales")
                            : handleOpen("Sales", true)
                      }
                    >
                      Add Sales Order Number
                    </Button>
                  </div>

                  <div style={{ padding: 5 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ color: "white", fontSize: 11 }}
                      onClick={() => handleOpen && handleOpen("Invoice")}
                    >
                      Add Invoice Number
                    </Button>
                  </div>
                </>
              )}

              {numSelected > 0 && (
                <>
                  {showTab === "Orders" && (
                    <>
                      <div style={{ padding: 5 }}>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          style={{ color: "white", fontSize: 11 }}
                          onClick={() =>
                            handleOpen && handleOpen("Glass Ordered")
                          }
                        >
                          Add Glass Order Date
                        </Button>
                      </div>

                      <div style={{ padding: 5 }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#ee3727",
                            color: "white",
                            fontSize: 11,
                          }}
                          onClick={handleOpenGlass}
                        >
                          Create Glass Order
                        </Button>
                      </div>
                    </>
                  )}

                  {showTab === "Quotes" && (
                    <div style={{ padding: 10 }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        style={{ color: "white", fontSize: 11 }}
                        onClick={handleOpenModalArchive}
                      >
                        {quote?.length && quote[0]?.status === "Archived"
                          ? "Move to Quotes"
                          : "Archive"}
                      </Button>
                    </div>
                  )}

                  {numSelected === 1 &&
                  quote &&
                  quote[0].status === "Quote" &&
                  showTab === "Quotes" ? (
                    <div style={{ padding: 5 }}>
                      <Button
                        style={{ color: "white", fontSize: 11 }}
                        onClick={openDuplicateModal}
                        size="small"
                        color="primary"
                        variant="contained"
                      >
                        <FileCopyIcon style={{ fontSize: 14 }} />
                        Duplicate
                      </Button>
                    </div>
                  ) : null}

                  {numSelected === 1 && showTab === "Service Repair" && (
                    <div style={{ padding: 5 }}>
                      <Button
                        style={{ color: "white", fontSize: 11 }}
                        onClick={handleOpenApt}
                        size="small"
                        color="primary"
                        variant="contained"
                      >
                        Add Appointment
                      </Button>
                    </div>
                  )}

                  {showTab === "Customer List" && role === "Admin" ? (
                    <>
                      {showEditBtn &&
                        showEditBtn() &&
                        showActive === "active" && (
                          <div style={{ padding: 5 }}>
                            <Button
                              onClick={handleOpenCustomerListModal}
                              size="small"
                              color="primary"
                              variant="contained"
                            >
                              Edit Account
                            </Button>
                          </div>
                        )}

                      <div style={{ padding: 5 }}>
                        <Button
                          onClick={handleOpenInactiveModal}
                          size="small"
                          color="primary"
                          variant="contained"
                        >
                          {showActive === "active" ? "Inactivate" : "Activate"}
                        </Button>
                      </div>
                    </>
                  ) : showTab === "Customer List" &&
                    role !== "Admin" &&
                    showActive === "active" ? (
                    <div style={{ padding: 5 }}>
                      <Button
                        onClick={handleOpenInactiveModal}
                        size="small"
                        color="primary"
                        variant="contained"
                      >
                        Inactivate
                      </Button>
                    </div>
                  ) : showTab === "Customer List" &&
                    role !== "Admin" &&
                    showActive === "inactive" ? null : (
                    <div style={{ padding: 5 }}>
                      <Button
                        style={{ color: "white", fontSize: 11 }}
                        onClick={handleOpenModal}
                        size="small"
                        color="primary"
                        variant="contained"
                      >
                        Change Status
                      </Button>
                    </div>
                  )}
                </>
              )}
            </StyledDivEndRow2>
          </StyledDivEndRow>

          {(showTab === "Quotes" ||
            showTab === "Orders" ||
            showTab === "Customer List" ||
            (showTab === "Service Repair" &&
              (role === "Admin" || role === "Inside Sales"))) && (
            <div style={{ padding: 5 }}>
              <ExportBtn
                showTab={showTab}
                searchTerm={searchTerm}
                searchTermFilter={searchTermFilter}
                isSearch={isSearch || false}
                showArchive={showArchive}
                showActive={showActive}
              />
            </div>
          )}
        </StyledDivSpaceBtw>
      </StyledToolbar>
    </>
  );
};

export default TableToolbar;
