import React, { useState, FC, useEffect } from "react";
import getModalStyle from "../../../../util/getModalStyle";
import {
  Typography,
  Button,
  TextField,
  CircularProgress,
  Modal,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { UseMutationResult } from "react-query";
import { AdminOrderNumsState } from "../QuotesTable";
import { StyledModalDiv } from "../../../../util/accordianStyles";
import { styled } from "@mui/material/styles";
import OrderAcknowledgePDF from "../../../../quotePDF/OrderAcknowledge";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { getUser } from "../../../../api/DzoneApi";
import { QuoteObj } from "../../../../GlobalTypes";

const StyledColDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginBottom: 20,
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: 20,
});

function getPlaceholder(name: string) {
  switch (name) {
    case "salesOrder":
      return "Enter Sales Order";
    case "invoiceNumber":
      return "Enter Invoice Number";
    case "depositDate":
      return "Enter Deposit Recieved";
    default:
      return "Enter Glass Ordered";
  }
}

interface AdminOrderModalProps {
  handleClose: () => void;
  modalText: string;
  open: boolean;
  mutation: UseMutationResult<void, unknown, void, unknown>;
  value: AdminOrderNumsState | {};
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  soMatch: boolean;
  sendOrderAck: string;
  handleCheckOrderAck: (event: SelectChangeEvent<string>) => void;
  orderAckErr: boolean;
  selectedQuote?: QuoteObj;
}

const AdminOrderModal: FC<AdminOrderModalProps> = ({
  handleClose,
  modalText,
  open,
  mutation,
  value,
  name,
  handleChange,
  soMatch,
  sendOrderAck,
  handleCheckOrderAck,
  orderAckErr,
  selectedQuote,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [userState, setUserState] = useState({
    factor: 0,
    region: "",
    terms: "",
    dealerTax: 0,
  });

  const handleGetUser = async () => {
    if (!selectedQuote?.userId) return;

    const userRes = await getUser(selectedQuote.userId);
    const userObj = {
      factor: userRes?.data?.factor || 0,
      region: userRes?.data?.region || "",
      terms: userRes?.data?.codOrTerms || "",
      dealerTax: userRes?.data?.dealerTax || 0,
    };
    setUserState(userObj);
  };

  useEffect(() => {
    if (!open) return;
    if (name !== "salesOrder") return;

    handleGetUser();
  }, [open]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      return mutation.mutate();
    }
  };

  const prevent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event?.preventDefault();
    }
  };

  const body = (
    <StyledModalDiv style={modalStyle} onKeyDown={handleKeyDown}>
      <h3 id="simple-modal-description" style={{ marginBottom: 40 }}>
        {modalText}
      </h3>

      <StyledColDiv>
        <StyledDivRow>
          {name === "invoiceNumber" && (
            <Typography style={{ paddingRight: 20 }}>
              Invoice Number:{" "}
            </Typography>
          )}

          <TextField
            label={getPlaceholder(name)}
            value={
              name in value 
                ? value[name]
                : ""
            }
            type={
              name === "depositDate" || 
              name === "glassOrderDate"
                ? "date"
                : "text"
            }
            name={name}
            onChange={handleChange}
            style={{ width: 180 }}
            size="small"
            variant="outlined"
            onKeyDown={prevent}
          />
        </StyledDivRow>

        {name === "invoiceNumber" && (
          <StyledDivRow>
            <Typography style={{ paddingRight: 20 }}>Invoice Date: </Typography>
            <TextField
              value={"invoiceDate" in value ? value.invoiceDate : ""}
              type="date"
              name={"invoiceDate"}
              onChange={handleChange}
              size="small"
              variant="outlined"
              onKeyDown={prevent}
            />
          </StyledDivRow>
        )}

        {soMatch && name === "salesOrder" && (
          <div style={{ display: "flex", padding: 10 }}>
            <Typography>
              Sales Order already exists do you want to add anyway?
            </Typography>
          </div>
        )}

        {name === "salesOrder" && (
          <StyledColDiv style={{ marginBottom: 10 }}>
            <FormControl style={{ padding: 10 }}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Select
                    size="small"
                    style={{ marginLeft: 10 }}
                    value={sendOrderAck}
                    onChange={handleCheckOrderAck}
                  >
                    <MenuItem value={"no"}>No</MenuItem>
                    <MenuItem value={"yes"}>Yes</MenuItem>
                  </Select>
                }
                label="Send Order Acknowledgement:"
              />
            </FormControl>

            {selectedQuote && (
              <>
                {userState?.factor &&
                "salesOrder" in value &&
                value.salesOrder ? (
                  <PDFDownloadLink
                    document={
                      <OrderAcknowledgePDF
                        data={selectedQuote as QuoteObj}
                        salesOrder={value.salesOrder}
                        dealer={userState}
                      />
                    }
                    fileName={`AGM_OrderAck_PO${value.salesOrder}.pdf`}
                  >
                    <Button
                      style={{ marginTop: 10, marginBottom: 10 }}
                      variant="contained"
                      color="customGrey"
                      size="small"
                    >
                      Preview Acknowledgement
                    </Button>
                  </PDFDownloadLink>
                ) : (
                  <Button
                    style={{ marginTop: 10, marginBottom: 10 }}
                    variant="contained"
                    color="customGrey"
                    size="small"
                    disabled={true}
                  >
                    Preview Acknowledgement
                  </Button>
                )}
              </>
            )}
          </StyledColDiv>
        )}

        {name === "salesOrder" && orderAckErr && (
          <div style={{ display: "flex", padding: 10 }}>
            <Typography style={{ color: "red" }}>
              Problem Sending Email
            </Typography>
          </div>
        )}
      </StyledColDiv>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button onClick={handleClose} variant="contained" color="inherit">
          Cancel
        </Button>
        {mutation.isLoading ? (
          <Button variant="contained" color="primary">
            <CircularProgress color="inherit" size={30} />
          </Button>
        ) : (
          <Button
            onClick={() => mutation.mutate()}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        )}
      </div>
    </StyledModalDiv>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default AdminOrderModal;
