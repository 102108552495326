import React, { useState, useEffect, lazy, Suspense, FC } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getLabelData } from "../../../api/DzoneApi";
import QRCode from "qrcode";
import { QuoteObj } from "../../../GlobalTypes";
import { styled } from "@mui/material/styles";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  color: "#4b4b4b",
}));

const GenerateLabels = lazy(
  () => import("../../../quotePDF/Labels/GenerateLabels"),
);

const GenerateShipLabels = lazy(
  () => import("../../../quotePDF/ShipLabels/GenerateShipLabels"),
);

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  position: "relative",
});

const StyledDivRowEndWide = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "300%",
});

const StyledDivRowEndCenter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
});

interface TableToolbarProdProps {
  numSelected: number;
  quote: QuoteObj[];
  unCheckAll: () => void;
}

const TableToolbarProd: FC<TableToolbarProdProps> = ({
  numSelected,
  quote,
  unCheckAll,
}) => {
  const [viewPDFLabels, setViewPDFLabels] = useState(false);

  const [labelData, setLabelData] = useState([]);

  const [qrCode, setQrCode] = useState("");

  const [viewShipLabels, setViewShipLabels] = useState(false);

  useEffect(() => {
    setViewPDFLabels(false);
    setViewShipLabels(false);
    setQrCode("");
  }, [numSelected]);

  const handleClickLabels = async (salesOrder: string) => {
    const response = await getLabelData(salesOrder);
    if (!response?.data?.data) return;

    setLabelData(response.data.data);
    setViewPDFLabels(true);
  };

  const handleClickShipLabels = async () => {
    if (!quote[0]) return;

    const url =
      process.env.NODE_ENV === "production"
        ? `https://agdealerzone.com/installDetails/${quote[0]._id}`
        : `http://192.168.1.124:3000/installDetails/${quote[0]._id}`;

    try {
      const qrSrc = await QRCode.toDataURL(url);
      setQrCode(qrSrc);
      setViewShipLabels(true);
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <>
      <StyledToolbar
        style={{ backgroundColor: numSelected > 0 ? "#57d5f2" : "" }}
      >
        <StyledDivRow>
          {numSelected > 0 && (
            <>
              <Typography
                style={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
              <button
                onClick={unCheckAll}
                style={{ position: "absolute", left: 100, borderRadius: 5 }}
              >
                Un-Check All
              </button>
            </>
          )}

          <StyledDivRowEndWide>
            <StyledDivRowEndCenter>
              {numSelected === 1 && (
                <>
                  {quote[0].salesOrder && quote[0].salesOrder !== "N/A" && (
                    <>
                      <div style={{ padding: 5 }}>
                        {!viewPDFLabels ? (
                          <Button
                            size="small"
                            color="primary"
                            style={{ fontSize: 11, color: "white" }}
                            variant="contained"
                            onClick={() =>
                              handleClickLabels(quote[0]?.salesOrder || "")
                            }
                          >
                            CNC Labels
                          </Button>
                        ) : (
                          <Suspense
                            fallback={
                              <Button
                                size="small"
                                style={{ fontSize: 11, color: "black" }}
                                variant="contained"
                              >
                                Loading...
                              </Button>
                            }
                          >
                            <GenerateLabels
                              unitLabels={labelData}
                              salesOrder={quote[0].salesOrder}
                            />
                          </Suspense>
                        )}
                      </div>

                      <div style={{ padding: 5 }}>
                        {!viewShipLabels ? (
                          <Button
                            size="small"
                            style={{ fontSize: 11, color: "white" }}
                            variant="contained"
                            color="primary"
                            onClick={handleClickShipLabels}
                          >
                            Shipping Labels
                          </Button>
                        ) : (
                          <Suspense
                            fallback={
                              <Button
                                size="small"
                                style={{ fontSize: 11, color: "black" }}
                                variant="contained"
                              >
                                Loading...
                              </Button>
                            }
                          >
                            <GenerateShipLabels
                              data={quote[0]}
                              qrCode={qrCode}
                            />
                          </Suspense>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </StyledDivRowEndCenter>
          </StyledDivRowEndWide>
        </StyledDivRow>
      </StyledToolbar>
    </>
  );
};

export default TableToolbarProd;
