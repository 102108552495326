interface ReturnObj {
  headJamb: number;
  sill: number;
  legJamb: number;
}

export function sliderKdWeights(
  width: number,
  height: number,
  config: string,
  isPocketing: boolean,
  isStainlessSill: boolean,
  withScreen: boolean,
  withSlidingScreen: boolean,
): ReturnObj {
  const panels = config.includes("Primary")
    ? config.split(" ")[0].length
    : config.split("-")[0].replace("P", "").length;

  let A = 0;
  let B = 0;
  let C = 0;

  if (isPocketing) {
    if (panels === 1) A = 1.46;
    if (panels === 2) A = 2.49;
    if (panels === 3) A = 3.81;
    if (panels === 4) A = 5.13;
  } else {
    if (panels === 1) A = 1;
    if (panels === 2) A = 2.3;
    if (panels === 3) A = 3.63;
    if (panels === 4) A = 4.9;
    if (panels === 5) A = 6.17;
  }

  if (isStainlessSill) A += 9.4;

  if (panels === 1) {
    B = 0.79;
    C = 1.98;
  } else if (panels === 2) {
    B = 1.39;
    C = 2.12;
  } else if (panels === 3) {
    B = 1.99;
    C = 2.5;
  } else if (panels === 4) {
    B = 2.59;
    C = 2.88;
  } else if (panels === 5) {
    B = 3.19;
    C = 3.26;
  }

  let sill = (width / 12) * A;
  if (!withSlidingScreen && withScreen && width < 384)
    sill += (width / 12) * 0.48;
  if (withSlidingScreen && width < 384) sill += (width / 12) * 0.75;

  const G = !isPocketing
    ? 2
    : !config.includes("-")
      ? 3
      : config.includes("-")
        ? 4
        : 1;

  let jamb = (height / 12) * B * G;
  if (!withSlidingScreen && withScreen && width < 384)
    jamb += (height / 12) * 0.48 * 2;

  let header = (width / 12) * C;
  if (!withSlidingScreen && withScreen && width < 384)
    header += (width / 12) * 0.48;

  return {
    headJamb: Math.round(header),
    sill: Math.round(sill),
    legJamb: Math.round(jamb),
  };
}

export function bifoldKdWeights(
  frameWidth: number,
  frameHeight: number,
  withScreen: boolean,
  sillType: string,
): ReturnObj {
  var headJamb = 0;
  var sill = 0;
  var legJamb = 0;
  //no screen
  if (!withScreen) {
    let sillMultiplier =
      sillType === "Ultra Guide" || sillType === "ADA Ultra Guide"
        ? 0.825
        : 2.55;

    headJamb = (frameWidth / 12) * 4.21;
    sill = (frameWidth / 12) * sillMultiplier;
    legJamb = (frameHeight / 12) * 5.02;
  }

  if (withScreen) {
    let sillMultiplier =
      sillType === "Ultra Guide" || sillType === "ADA Ultra Guide"
        ? 0.87
        : 3.16;

    headJamb = (frameWidth / 12) * 4.74;
    sill = (frameWidth / 12) * sillMultiplier;
    legJamb = (frameHeight / 12) * 6.04;
  }

  return {
    headJamb: Math.round(headJamb),
    sill: Math.round(sill),
    legJamb: Math.round(legJamb),
  };
}

export function fixedPanelKdWeights(
  config: string,
  width: number,
  height: number,
): ReturnObj {
  if (config === "Fixed Bi-fold") {
    let sillMultiplier = 2.55;

    const headJamb = (width / 12) * 4.21;
    const sill = (width / 12) * sillMultiplier;
    const legJamb = (height / 12) * 5.02;

    return {
      headJamb: Math.round(headJamb),
      sill: Math.round(sill),
      legJamb: Math.round(legJamb),
    };
  }

  if (config === "Fixed Slider") {
    let A = 1.26;
    let B = 0.79;
    let C = 1.98;

    let sill = (width / 12) * A;
    let jamb = (height / 12) * B * 2;
    let header = (width / 12) * C;

    return {
      headJamb: Math.round(header),
      sill: Math.round(sill),
      legJamb: Math.round(jamb),
    };
  }

  let headJamb = 0;
  let sill = 0;
  let legJamb = 0;
  const jambLegMultiplier = 2;

  const swingDirection = config === "Fixed Inswing" ? "Inswing" : "Outswing";

  if (swingDirection === "Outswing") {
    headJamb = (width / 12) * 1.77;
    sill = (width / 12) * 1.43;
    legJamb = (height / 12) * 1.77 * jambLegMultiplier;
  } else if (swingDirection === "Inswing") {
    headJamb = (width / 12) * 1.45;
    sill = (width / 12) * 1.93;
    legJamb = (height / 12) * 1.45 * jambLegMultiplier;
  }

  return {
    headJamb,
    sill,
    legJamb,
  };
}
