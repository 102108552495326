import React, {
  useState,
  useEffect,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import SearchBar from "./SearchBar";
import EnhancedTableHead from "./EnhancedTableHead";
import TableToolbar from "./TableToolbar";
import TableToolbarProd from "./TableToolbarProd";
import TableToolbarDealer from "./TableToolbarDealer";
import ConfirmationModal from "./Modals/ConfirmationModal";
import SalesOrderWarningModal from "./Modals/SalesOrderWarningModal";
import { useQueryClient, useMutation } from "react-query";
import AdminOrderModal from "./Modals/AdminOrderModal";
import DuplicateQuoteModal from "./Modals/DuplicateQuoteModal";
import ArchiveModal from "./Modals/ArchiveModal";
import RoleTableCells from "./RoleTableCells";
import api from "../../../api/api";
import {
  postStatusChange,
  getUser,
  postCloneQuote,
  postGlassOrderDate,
  getTableData,
  archiveQuotes,
  unArchiveQuotes,
  sendOrderAcknowledgement
} from "../../../api/DzoneApi";
import SaberisModal from "./Modals/SaberisModal";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { QuoteObj } from "../../../GlobalTypes";
import { TableState } from "../../../pages/DealerZone";
import ReactPDF from "@react-pdf/renderer"
import OrderAcknowledgePDF from "../../../quotePDF/OrderAcknowledge";

export interface DealerState {
  factor: number;
  region: string;
  terms: string;
  dealerTax: number;
}

interface QuotesTableProps {
  searchTerm: string;
  filterBy: string;
  isSearch: boolean;
  setIsSearch: Dispatch<SetStateAction<boolean>>;
  totalLength: number;
  page: number;
  rowsPerPage: number;
  userId: string;
  showTab: string;
  data: string | { currentPage: QuoteObj[]; totalResults?: number };
  isLoading: boolean;
  error: unknown;
  isFetching: boolean;
  setState: Dispatch<SetStateAction<TableState>>;
  setReSearch: Dispatch<SetStateAction<boolean>>;
  handleArchive?: (e: SelectChangeEvent<string>) => void;
  showArchive?: string;
}

export interface AdminOrderNumsState {
  salesOrder: string;
  invoiceNumber: string;
  invoiceDate: string;
  depositDate: string;
  glassOrderDate: string;
}

const StyledRootDiv = styled("div")({
  width: "100%",
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
  position: "relative",
}));

const StyledTypography = styled(Typography)({
  display: "flex",
  justifyContent: "flex-start",
  padding: 20,
  fontWeight: "bold",
});

const StyledBackdrop = styled(Backdrop)({
  position: "absolute",
  width: "100%",
  height: "50vh",
  zIndex: 10,
});

const QuotesTable: FC<QuotesTableProps> = ({
  searchTerm,
  filterBy,
  isSearch,
  setIsSearch,
  totalLength,
  page,
  rowsPerPage,
  userId,
  showTab,
  data,
  isLoading,
  error,
  isFetching,
  setState,
  setReSearch,
  handleArchive,
  showArchive,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const role = JSON.parse(sessionStorage.getItem("role") || JSON.stringify(""));

  const [selectedQuotes, setSelectedQuotes] = useState<QuoteObj[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setState((prevState) => ({ ...prevState, searchTerm: value }));
  };

  const handleFilterByChange = (event: SelectChangeEvent<string>) =>
    setState((prevState) => ({ ...prevState, filterBy: event.target.value }));

  const onKeyDown = (event: React.KeyboardEvent) =>
    event.key === "Enter" && filterSearch();

  const filterSearch = () => {
    if (!searchTerm) {
      setIsSearch(false);
      return;
    }
    setCutsheetData([]);
    setState((prevState) => ({ ...prevState, page: 0 }));

    if (isSearch) setReSearch((prev) => !prev);

    setIsSearch(true);
  };

  const handleReset = () => {
    setIsSearch(false);
    setState((prevState) => ({ ...prevState, page: 0, searchTerm: "" }));
    setCutsheetData([]);

    queryClient.invalidateQueries(showTab);
  };

  const [adminOrderNums, setAdminOrderNums] = useState<
    AdminOrderNumsState | {}
  >({
    salesOrder: "",
    invoiceNumber: "",
    invoiceDate: "",
    depositDate: "",
    glassOrderDate: "",
  });

  const [sendOrderAck, setSendOrderAck] = useState("no");

  const [orderAckErr, setOrderAckErr] = useState(false);

  const handleCheckOrderAck = (event: SelectChangeEvent<string>) => setSendOrderAck(event.target.value);

  const handleAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAdminOrderNums({ ...adminOrderNums, [name]: value });
  };

  const [soMatch, setSoMatch] = useState(false);

  const adminButtonCommonLogic = async (
    endpoint: string,
    body: { [key: string]: string | Date },
  ) => {
    if (selectedQuotes.length !== 1) return;

    const response = await api.put(endpoint, {
      quoteId: selectedQuotes[0]._id,
      soMatch,
      ...body,
    });

    if (!soMatch && response.data === "Sales Order already exists") {
      return setSoMatch(true);
    }
    setSoMatch(false);

    if (response.data.error) {
      return alert("Could Not Update Quote");
    }

    if(endpoint === "/api/addSalesOrder" && sendOrderAck === "yes"){
      setOrderAckErr(false)
      const userRes = await getUser(selectedQuotes[0].userId);
      const userObj = {
        factor: userRes?.data?.factor || 0,
        region: userRes?.data?.region || "",
        terms: userRes?.data?.codOrTerms || "",
        dealerTax: userRes?.data?.dealerTax || 0
      }
      const pdf = await ReactPDF.pdf(
        <OrderAcknowledgePDF 
          data={selectedQuotes[0]} 
          salesOrder={body.salesOrder as string} 
          dealer={userObj}
        />
      ).toBlob(); // Convert PDF to Blob
      const reader = new FileReader();
      reader.readAsDataURL(pdf);
      reader.onloadend = async() => {
        const base64data = reader.result;

        try{
          await sendOrderAcknowledgement(JSON.stringify({
            pdfBase64: base64data, 
            filename: `AGM_OrderAck_PO${body.salesOrder}.pdf`,
            email: userRes.data.email
          }));
        }catch(err){
          return setOrderAckErr(true)
        }
      }
    }

    setAdminOrderNums({});
    setSelectedQuotes([]);
    handleAdminClose();

    return queryClient.invalidateQueries("Orders");
  };

  const adminGlassOrderDate = async () => {
    if (!selectedQuotes.length) return;

    if ("glassOrderDate" in adminOrderNums && adminOrderNums.glassOrderDate) {
      const ids = selectedQuotes.map((itm) => itm._id);

      const response = await postGlassOrderDate(
        ids,
        adminOrderNums.glassOrderDate,
      );

      if (response.data.error) {
        alert("Could Not Update Quote");
        return;
      }

      setAdminOrderNums({});
      handleAdminClose();

      return queryClient.invalidateQueries("Orders");
    }

    return;
  };

  const [adminOrderType, setAOT] = useState("");

  const [openAdminModal, setOAM] = useState(false);

  const [warnModal, setWarnModal] = useState(false);

  const handleWarnModalClose = () => setWarnModal(false);

  const handleAdminOpen = (type: string, showWarningModal?: boolean) => {
    if (showWarningModal) {
      setWarnModal(true);
      return;
    }
    setAOT(type);
    setSoMatch(false);
    setOAM(true);
  };
  const handleAdminClose = () => {
    setOAM(false);
    setWarnModal(false);
    setAdminOrderNums({
      salesOrder: "",
      invoiceNumber: "",
      invoiceDate: "",
      depositDate: "",
      glassOrderDate: "",
    })
  };

  const salesOrderMutation = useMutation(() =>
    adminButtonCommonLogic("/api/addSalesOrder", {
      salesOrder:
        "salesOrder" in adminOrderNums ? adminOrderNums.salesOrder : "",
    }),
  );

  const invoiceMutation = useMutation(() =>
    adminButtonCommonLogic("/api/addInvoice", {
      invoiceNumber:
        "invoiceNumber" in adminOrderNums ? adminOrderNums.invoiceNumber : "",
      invoiceDate:
        "invoiceDate" in adminOrderNums && adminOrderNums.invoiceDate
          ? new Date(
              adminOrderNums.invoiceDate.replace(/-/g, "/"),
            ).toLocaleDateString("en-US")
          : new Date(),
    }),
  );

  const depositDateMutation = useMutation(() =>
    adminButtonCommonLogic("/api/addDepositDate", {
      depositDate:
        "depositDate" in adminOrderNums ? adminOrderNums.depositDate : "",
    }),
  );

  const glassOrderMutation = useMutation(() => adminGlassOrderDate());

  const mutation = useMutation(
    async (status: string) => {
      if (!status) return null;

      setLoadingModal(true);
      const ids = selectedQuotes.map((itm) => itm._id);
      const res = await postStatusChange(showTab, ids, status);

      if (res.data === "Status Changed") {
        if (isSearch && showTab === "Quotes") {
          setIsSearch(false);
          setState((prevState) => ({ ...prevState, page: 0 }));
        }
        resetAfterFetch();
      }
      if (isSearch && showTab === "Orders") {
        setIsSearch(true);
        return queryClient.invalidateQueries("Orders");
      }

      queryClient.invalidateQueries("Quotes");
      return queryClient.invalidateQueries("Orders");
    },
    {
      onError: (error) => {
        console.error("Error in mutation:", error);
      },
    },
  );

  const archiveMutation = useMutation(async () => {
    setLoadingModal(true);

    function archiveReset() {
      setSelectedQuotes([]);
      setLoadingModal(false);
      handleCloseModalArchive();
      return queryClient.invalidateQueries("Quotes");
    }

    const ids = selectedQuotes.map((itm) => itm._id);

    if (selectedQuotes[0].status === "Archived") {
      const res = await unArchiveQuotes(ids);

      if (res.data === "unarchived") {
        return archiveReset();
      }
    } else {
      const res = await archiveQuotes(ids, archiveReason, archiveReasonText);

      if (res.data === "archived") {
        return archiveReset();
      }
    }
  });

  const resetAfterFetch = () => {
    setSelectedQuotes([]);
    setLoadingModal(false);
    handleCloseModal();
  };

  const handleViewClick = (quote: QuoteObj) => {
    localStorage.clear();
    sessionStorage.setItem("quoteId", JSON.stringify(quote._id));
    sessionStorage.setItem("quoteNumber", JSON.stringify(quote.quoteNumber));

    if (role === "Admin" || role === "Inside Sales") {
      navigate(`/config/${quote.userId}`, {
        state: { userId: quote.userId, resetStore: true },
      });
    } else {
      const orderSummaryId = role === "Dealer" ? userId : quote.userId;

      if (quote.status === "Archived") {
        sessionStorage.setItem("refresh", JSON.stringify(true));
        navigate(`/orderSummary/${orderSummaryId}`, {
          state: { userId: quote.userId },
        });
      } else if (quote.status !== "Quote" && quote.status !== "Archived") {
        sessionStorage.setItem("refresh", JSON.stringify(true));
        navigate(`/orderSummary/${orderSummaryId}`, {
          state: { userId: quote.userId },
        });
      } else {
        navigate(`/config/${orderSummaryId}`, {
          state: { userId: orderSummaryId },
        });
      }
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setState((prevState) => ({ ...prevState, page: newPage }));

    isSearch && setIsSearch(true);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let RPP = parseInt(event.target.value, 10);
    setState((prevState) => ({ ...prevState, rowsPerPage: RPP }));

    isSearch && setIsSearch(true);
  };

  const handleCheck = (event: React.MouseEvent<any>, rowQuote: QuoteObj) => {
    if (showTab === "Quotes" && selectedQuotes.length) {
      if (
        selectedQuotes[0].status === "Quote" &&
        rowQuote.status === "Archived"
      ) {
        return alert("Can't select Archive when Quote is selected");
      }

      if (
        selectedQuotes[0].status === "Archived" &&
        rowQuote.status === "Quote"
      ) {
        return alert("Can't select Quote when Archive is selected");
      }
    }

    const quoteIndex = selectedQuotes
      .map((unit) => unit._id)
      .indexOf(rowQuote._id);
    let newSelQuotes: QuoteObj[] = [];

    if (quoteIndex === -1) {
      newSelQuotes = newSelQuotes.concat(selectedQuotes, rowQuote);
    } else if (quoteIndex === 0) {
      newSelQuotes = newSelQuotes.concat(selectedQuotes.slice(1));
    } else if (quoteIndex === selectedQuotes.length - 1) {
      newSelQuotes = newSelQuotes.concat(selectedQuotes.slice(0, -1));
    } else if (quoteIndex > 0) {
      newSelQuotes = newSelQuotes.concat(
        selectedQuotes.slice(0, quoteIndex),
        selectedQuotes.slice(quoteIndex + 1),
      );
    }

    setSelectedQuotes(newSelQuotes);
  };

  const unCheckAll = () => {
    setSelectedQuotes([]);
  };

  const isSelected = (id: string) =>
    selectedQuotes.map((itm) => itm._id).indexOf(id) !== -1;

  useEffect(() => {
    if (
      !isFetching ||
      role === "Dealer" ||
      role === "Manager" ||
      role === "Company Manager"
    )
      return;
    viewPdfId && setViewPdfId("");
    setDealer({ factor: 0, region: "", terms: "", dealerTax: 0 });
  }, [isFetching]);

  const [viewPdfId, setViewPdfId] = useState("");

  const [dealer, setDealer] = useState({
    factor: 0,
    region: "",
    terms: "",
    dealerTax: 0,
  });

  const [cutsheetData, setCutsheetData] = useState([]);

  const [loadingCutsheet, setLoading] = useState(false);

  const handleViewPdf = async (id: string, user: string, pdfLabel: string) => {
    if (pdfLabel === "prod") {
      setViewPdfId(`${id}${pdfLabel}`);
      setLoading(true);
      const response = await getTableData(id);
      if (response.data) setCutsheetData(response.data);
    }

    await handleDealerRowData(user, id, pdfLabel);
    if (pdfLabel === "prod") setLoading(false);
  };

  const handleDealerRowData = async (
    user: string,
    id: string,
    pdfLabel: string,
  ) => {
    const res = await getUser(user);

    if (res.data.error) {
      return;
    } else {
      setDealer({
        factor: res.data.factor,
        region: res.data.region,
        terms: res.data.codOrTerms,
        dealerTax: res.data.dealerTax || 0,
      });
      pdfLabel !== "prod" && setViewPdfId(`${id}${pdfLabel}`);
    }

    return;
  };

  const [duplicate, setDuplicate] = useState({
    open: false,
    error: false,
    quoteNumber: 0,
    user: "",
  });

  const [loadDuplicate, setLoadDuplicate] = useState(false);

  const openDuplicateModal = () => {
    if (selectedQuotes.length !== 1) return;

    setDuplicate({
      open: true,
      error: false,
      quoteNumber: selectedQuotes[0].quoteNumber,
      user: selectedQuotes[0].userId || "",
    });
  };

  const closeDuplicateModal = () => {
    setDuplicate({ open: false, error: false, quoteNumber: 0, user: "" });
    setLoadDuplicate(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [archiveModal, setArchiveModal] = useState(false);

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModalArchive = () => setArchiveModal(false);

  const [openSaberis, setOpenSaberis] = useState(false);

  const handleCloseSaberis = () => setOpenSaberis(false);

  const handleOpenSaberis = () => setOpenSaberis(true);

  const handleOpenModalArchive = () =>
    selectedQuotes.length && setArchiveModal(true);

  const duplicateQuote = async () => {
    if (!duplicate.open || !duplicate.quoteNumber) return;
    setLoadDuplicate(true);

    const response = await postCloneQuote(
      duplicate.quoteNumber,
      duplicate.user,
    );

    if (response.data.error) {
      setDuplicate((previous) => ({ ...previous, error: true }));
      setLoadDuplicate(false);
      return;
    }

    closeDuplicateModal();
    return queryClient.invalidateQueries("Quotes");
  };

  const [hoverId, setHoverId] = useState("");

  const [archiveReason, setArchiveReason] = useState("");

  const [archiveReasonText, setArchiveReasonText] = useState("");

  const handleChangeArchive = (event: SelectChangeEvent<string>) => {
    setArchiveReason(event.target.value);
  };

  const handleChangeTextBoxArchive = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setArchiveReasonText(event.target.value);
  };

  const mouseEnter = (unitId: string) => {
    setHoverId(unitId);
  };

  const mouseLeave = () => {
    if (!selectedQuotes.length) {
      setHoverId("");
    }
  };

  const rows =
    !isFetching &&
    data !== "No Quotes Found" &&
    typeof data === "object" &&
    "currentPage" in data
      ? data.currentPage
      : [];

  if (error)
    return <div>An error has occured, please try refreshing the page.</div>;

  return (
    <>
      <SearchBar
        filterBy={filterBy}
        handleFilterByChange={handleFilterByChange}
        handleReset={handleReset}
        onKeyDown={onKeyDown}
        filterSearch={filterSearch}
        handleChange={handleChange}
        showTab={showTab}
        searchTerm={searchTerm}
        showArchive={showArchive}
        handleArchive={handleArchive}
      />

      {!isFetching && data === "No Quotes Found" && isSearch ? (
        <StyledTypography>
          No {showTab === "Insights" ? "Quotes" : showTab} Found for your Search
        </StyledTypography>
      ) : !isFetching && data === "No Quotes Found" && !isSearch ? (
        <StyledTypography>
          No {showTab === "Insights" ? "Quotes" : showTab} Found
        </StyledTypography>
      ) : (
        <StyledRootDiv>
          {(role === "Admin" || role === "Inside Sales") &&
            showTab !== "Insights" && (
              <>
                <AdminOrderModal
                  selectedQuote={selectedQuotes[0]}
                  open={openAdminModal}
                  handleClose={handleAdminClose}
                  modalText={
                    adminOrderType === "Sales"
                      ? "Add A Sales Order"
                      : adminOrderType === "Invoice"
                        ? "Add an Invoice Number"
                        : adminOrderType === "Deposit"
                          ? "Add Date of Received Deposit"
                          : "Add Glass Order Date"
                  }
                  mutation={
                    adminOrderType === "Sales"
                      ? salesOrderMutation
                      : adminOrderType === "Invoice"
                        ? invoiceMutation
                        : adminOrderType === "Deposit"
                          ? depositDateMutation
                          : glassOrderMutation
                  }
                  name={
                    adminOrderType === "Sales"
                      ? "salesOrder"
                      : adminOrderType === "Invoice"
                        ? "invoiceNumber"
                        : adminOrderType === "Deposit"
                          ? "depositDate"
                          : "glassOrderDate"
                  }
                  value={adminOrderNums}
                  soMatch={soMatch}
                  handleChange={handleAdminChange}
                  sendOrderAck={sendOrderAck}
                  handleCheckOrderAck={handleCheckOrderAck}
                  orderAckErr={orderAckErr}
                />

                <SalesOrderWarningModal
                  open={warnModal}
                  handleClose={handleWarnModalClose}
                  nextModal={handleAdminOpen}
                />
              </>
            )}

          <ArchiveModal
            handleClose={handleCloseModalArchive}
            mutation={archiveMutation}
            open={archiveModal}
            archiveReason={archiveReason}
            handleChange={handleChangeArchive}
            status={selectedQuotes[0]?.status}
            archiveReasonTextBox={archiveReasonText}
            handleChangeTextBox={handleChangeTextBoxArchive}
          />

          <DuplicateQuoteModal
            handleClose={closeDuplicateModal}
            open={duplicate.open}
            loading={loadDuplicate}
            quoteNumber={duplicate.quoteNumber}
            error={duplicate.error}
            duplicateQuote={duplicateQuote}
          />

          <ConfirmationModal
            mutation={mutation}
            loading={loadingModal}
            showTab={showTab}
            handleClose={handleCloseModal}
            open={openModal}
          />

          <SaberisModal
            handleClose={handleCloseSaberis}
            open={openSaberis}
            quoteId={selectedQuotes.length === 1 ? selectedQuotes[0]._id : ""}
          />

          <StyledPaper>
            {showTab === "Insights" ? null : role === "Production" ? (
              <TableToolbarProd
                quote={selectedQuotes}
                numSelected={selectedQuotes.length}
                unCheckAll={unCheckAll}
              />
            ) : role === "Admin" || role === "Inside Sales" ? (
              <TableToolbar
                handleOpenModal={handleOpenModal}
                showTab={showTab}
                quote={selectedQuotes}
                numSelected={selectedQuotes.length}
                handleOpen={handleAdminOpen}
                unCheckAll={unCheckAll}
                searchTerm={searchTerm}
                searchTermFilter={filterBy}
                isSearch={isSearch}
                openDuplicateModal={openDuplicateModal}
                handleOpenModalArchive={handleOpenModalArchive}
                showArchive={showArchive}
              />
            ) : (
              <TableToolbarDealer
                showTab={showTab}
                numSelected={selectedQuotes.length}
                unCheckAll={unCheckAll}
                searchTerm={searchTerm}
                searchTermFilter={filterBy}
                isSearch={isSearch}
                handleOpenModalArchive={handleOpenModalArchive}
                status={selectedQuotes[0]?.status}
                showArchive={showArchive}
                openDuplicateModal={openDuplicateModal}
                openSaberisModal={handleOpenSaberis}
              />
            )}

            {isFetching || isLoading ? (
              <StyledBackdrop open={isFetching || isLoading}>
                <CircularProgress color="primary" />
              </StyledBackdrop>
            ) : (
              <>
                <TableContainer>
                  <Table
                    style={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"small"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead showTab={showTab} />

                    <TableBody>
                      {rows.length
                        ? rows.map((row) => {
                            const isItemSelected = isSelected(row._id);
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row._id}
                                onMouseEnter={() => mouseEnter(row._id)}
                                onMouseLeave={mouseLeave}
                              >
                                <RoleTableCells
                                  row={row}
                                  tab={showTab}
                                  role={role}
                                  handleViewClick={handleViewClick}
                                  viewPdfId={viewPdfId}
                                  loadingCutsheet={loadingCutsheet}
                                  dealer={dealer}
                                  isItemSelected={isItemSelected}
                                  handleCheck={handleCheck}
                                  cutsheetData={cutsheetData}
                                  handleViewPdf={handleViewPdf}
                                  numSelected={selectedQuotes.length}
                                  hoverId={hoverId}
                                />
                              </TableRow>
                            );
                          })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[15, 20, 25]}
                  component="div"
                  count={totalLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </StyledPaper>
        </StyledRootDiv>
      )}
    </>
  );
};

export default QuotesTable;
