import React, { FC } from "react";
import { Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import Header from "./pdfComponents/Header";
import SummaryPage from "./pdfComponents/SummaryPage";
import { QuoteObj } from "../GlobalTypes";
import { UnitObj } from "../util/quoteCartStorage";
import sortUnits from "../util/sortUnits";
import { loopUnits } from "./DealerCost";
import findShipLabelData from "./ShipLabels/findShipLabelData";

const styles = StyleSheet.create({
  col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingRight: 10,
    paddingBottom: 10,
    height: "100%",
    width: "33%",
  },
  colInside: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "center",
    marginTop: 5,
  },
  headerDiv: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 10,
  },
  headerText: {
    fontFamily: "Roboto",
    fontSize: 10,
  },
  colLargeText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    borderBottom: "2px solid black",
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 5,
  },
  boldFont: {
    fontFamily: "Roboto-Bold",
    fontWeight: "bold",
    fontSize: 8,
    paddingRight: 5,
  },
  font: {
    fontFamily: "Roboto",
    fontSize: 8,
  },
});

interface Props {
  data: QuoteObj;
  salesOrder: string;
  dealer: {
    factor: number;
    region: string;
    terms: string;
    dealerTax: number;
  };
}

function setLabel(text: string) {
  if (!text) {
    return <Text style={styles.font}>N/A</Text>;
  }
  const tooLong = text.length > 30;

  if (tooLong) {
    const array = text.split(" ");
    let a = ''
    let b = ''
    let count = 0;

    array.forEach(itm => {
      const index = array.indexOf(itm);
      const isLast = index === array.length - 1;
      const length = isLast ? itm.length : itm.length + 1;

      const text = isLast ? itm : itm + " "
      count += length
      
      if(count <= 30){
        a += text
      }else{
        b += text
      }
    });

    return (
      <View style={styles.colLargeText}>
        <Text style={styles.font}>{a}</Text>
        <Text style={styles.font}>{b}</Text>
      </View>
    );
  }

  return <Text style={styles.font}>{text}</Text>;
}

const OrderAcknowledgePDF: FC<Props> = ({ data, salesOrder, dealer }) => {
  const leftCol = [
    { label: "Project Name:", quoteKey: "jobName" },
    { label: "AG Sales Rep:", quoteKey: "agSalesperson" },
    { label: "Sign-off Date:", quoteKey: "dateOrdered" },
    { label: "Lead Time:", quoteKey: "leadTime" },
    { label: "Approximate Ship Date:", quoteKey: "estShipDate" },
  ];

  const middleCol = [
    { label: "Dealer:", quoteKey: "dealer" },
    { label: "Dealer Rep:", quoteKey: "salesperson" },
    { label: "Email:", quoteKey: "email" },
    { label: "Phone:", quoteKey: "phone" },
  ];

  const rightCol = [
    { label: "Customer Name:", quoteKey: "customerName" },
    { label: "Customer Email:", quoteKey: "customerEmail" },
    { label: "Customer Phone:", quoteKey: "customerPhone" },
  ];

  const orderedUnits = sortUnits(data.units as UnitObj[]);

  const { customClad } = loopUnits(
    data.hingeSeries || 1,
    data.units as UnitObj[],
    true,
    data.dateOrdered
  );

  const { totalItems, labelData } = findShipLabelData(
    orderedUnits as UnitObj[],
    false,
    "seriesOverride" in data && data.seriesOverride
      ? data.seriesOverride
      : "hingeSeries" in data && data.hingeSeries
      ? `Series ${data.hingeSeries}`
      : ""
  );

  const unitsWithPartsNumber = orderedUnits?.map((itm) => {
    const labelObj = labelData.find(
      (obj) =>
        "id" in obj && obj.id === itm._id && obj.description === "Parts Box"
    );

    if (labelObj) {
      const partsItemAmount = Number(labelObj.item?.split("of ")[1]);
      itm["partsItemAmount"] = partsItemAmount;
    }

    return itm;
  });

  return (
    <Document>
      <Page wrap>
        <Header
          data={data}
          customerOrDealer=""
          fixed={true}
          title="ORDER ACKNOWLEDGEMENT"
          dealerLogo=""
          salesOrder={salesOrder}
        />
        <View style={styles.row}>
          <View style={[styles.col, { borderRight: "1px solid black" }]}>
            <View style={styles.colInside}>
              <View style={styles.headerDiv}>
                <Text style={styles.headerText}>Order Details</Text>
              </View>
              {leftCol.map((itm) => (
                <View style={styles.rowCenter}>
                  <Text style={styles.boldFont}>{itm.label}</Text>
                  {(itm.quoteKey === "estShipDate" || itm.quoteKey === "dateOrdered") && data[itm.quoteKey] ? (
                    <Text style={styles.font}>
                      {new Date(data[itm.quoteKey] as Date).toLocaleDateString(
                        "en-US"
                      )}
                    </Text>
                  ) 
                  : setLabel(data[itm.quoteKey])
                  }
                </View>
              ))}
            </View>
          </View>

          <View style={[styles.col, { borderRight: "1px solid black" }]}>
            <View style={styles.colInside}>
              <View style={styles.headerDiv}>
                <Text style={styles.headerText}>Dealer Information</Text>
              </View>
              {middleCol.map((itm) => (
                <View style={styles.rowCenter}>
                  <Text style={styles.boldFont}>{itm.label}</Text>
                  {setLabel(data[itm.quoteKey])}
                </View>
              ))}
              {data.checkedDealer ?
              <>
              <View style={styles.rowCenter}>
                <Text style={styles.boldFont}>Ship To:</Text>
                <Text style={styles.font}>Dealer</Text>
              </View>
              <View style={styles.rowCenter}>
                <Text style={styles.boldFont}>Address:</Text>
                {setLabel(`${data["address"]}, ${data['city']} ${data['state']} ${data['zip']}`)}
              </View>
              </>
              :
              <Text />
              }
            </View>
          </View>

          <View style={styles.col}>
            <View style={styles.colInside}>
              <View style={styles.headerDiv}>
                <Text style={styles.headerText}>Customer Information</Text>
              </View>

              {rightCol.map((itm) => (
                <View style={styles.rowCenter}>
                  <Text style={styles.boldFont}>{itm.label}</Text>
                  {setLabel(data[itm.quoteKey])}
                </View>
              ))}

              {data.checkedJobsite ?
              <>
              <View style={styles.rowCenter}>
                <Text style={styles.boldFont}>Ship To:</Text>
                <Text style={styles.font}>Jobsite</Text>
              </View>
              <View style={styles.rowCenter}>
                <Text style={styles.boldFont}>Address:</Text>
                {setLabel(`${data["address"]}, ${data['city']} ${data['state']} ${data['zip']}`)}
              </View>
              <View style={styles.rowCenter}>
                <Text style={styles.boldFont}>JS Contact:</Text>
                <Text style={styles.font}>{data.jobsiteContact || "N/A"}</Text>
              </View>
              <View style={styles.rowCenter}>
                <Text style={styles.boldFont}>JS Contact #:</Text>
                <Text style={styles.font}>{data.jobsiteContactNumber || "N/A"}</Text>
              </View>
              </>
              :
              <Text />
              }
            </View>
          </View>
        </View>

        <SummaryPage
          data={data}
          orderedUnits={unitsWithPartsNumber || []}
          suggestedRetail={data.suggestedRetailPrice || "N/A"}
          factor={data.factor || 0}
          grandTotal={data.grandTotal || "N/A"}
          codOrTerms={dealer?.terms || ""}
          isCustomer={false}
          margin={0}
          salesTax={0}
          customClad={customClad}
          hidePricing={""}
          dealerTax={dealer?.dealerTax || 0}
          totalFrameItems={totalItems}
        />
      </Page>
    </Document>
  );
};

export default OrderAcknowledgePDF;
