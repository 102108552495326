import React, { FC } from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import DealerDrawings from "./DealerDrawings/DealerDrawings";
import DrawingsCorner from "./DealerDrawings/Corner/DrawingsCorner";
import DrawingsCorner2 from "./DealerDrawings/Corner/DrawingsCorner2";
import Header from "./pdfComponents/Header";
import Page1 from "./pdfComponents/Page1";
import Disclosures from "./pdfComponents/Disclosures";
import UnitDetails from "./pdfComponents/UnitDetails";
import SummaryPage from "./pdfComponents/SummaryPage";
import CutSheets from "./CutSheets/CutSheets";
import sortUnits from "../util/sortUnits";
import { styles } from "./pdfComponents/Styles";
import letters from "../util/letters";
import fractionToInt from "../util/fractionToInt";
import { UnitObj } from "../util/quoteCartStorage";
import { CutsheetDataObj } from "../GlobalTypes";
import { QuoteObj } from "../GlobalTypes";

export function loopUnits(
  hingeSeries: number,
  units: UnitObj[],
  isOrder?: boolean,
  dateOrdered?: string | Date,
) {
  let customClad = false;
  let bifoldSeries2 = false;

  let series2Min = 42;

  if (isOrder && dateOrdered) {
    const dateOfChange = new Date("08/11/2023");
    const pastDateOfChange = new Date(dateOrdered) > dateOfChange;
    series2Min = pastDateOfChange ? 42 : 39;
  }

  for (var i = 0; i < units.length; i++) {
    var unit = units[i];

    const isCorner = unit.doorType.includes("Corner");

    if (!unit.panelWidth) {
      continue;
    }

    if (isCorner && !unit.panelWidthRightCorner) {
      continue;
    }

    if (customClad && bifoldSeries2) break;

    if (!customClad) {
      customClad = unit.exteriorCladColor === "Custom Color (upcharge applies)";
    }

    if (!bifoldSeries2) {
      bifoldSeries2 =
        isCorner &&
        unit.panelWidthRightCorner &&
        (Number(unit.panelWidth.split(" ")[0]) >= series2Min ||
          Number(unit.panelWidthRightCorner.split(" ")[0]) >= series2Min)
          ? true
          : unit.doorType.includes("Bi-fold") &&
              fractionToInt(
                unit.panelWidth.split(" ")[0],
                unit.panelWidth.split(" ")[1] || "",
              ) >= series2Min
            ? true
            : false;
    }
  }

  if (hingeSeries === 2) {
    bifoldSeries2 = true;
  } else if (hingeSeries === 1) {
    bifoldSeries2 = false;
  }

  return { customClad, bifoldSeries2 };
}

const signline = "${signfield:1:y:______}";
const dateSigned = "${datefield:1:y:Date_Signed}";

interface DealerCostProps {
  data: QuoteObj;
  codOrTerms: string;
  poNumber: string;
  region: string;
  factor: number;
  grandTotal: string;
  suggestedRetail: string;
  isOrder: boolean;
  salesOrder: string;
  isProduction?: boolean;
  cutsheetData: CutsheetDataObj[] | undefined;
  series: string;
  dealerTax: number;
}

const DealerCost: FC<DealerCostProps> = ({
  data,
  codOrTerms,
  poNumber,
  region,
  factor,
  grandTotal,
  suggestedRetail,
  isOrder,
  salesOrder,
  isProduction,
  cutsheetData,
  series,
  dealerTax,
}) => {
  const withSalesOrder =
    (isOrder || isProduction) && salesOrder ? salesOrder : "";

  const { customClad, bifoldSeries2 } = loopUnits(
    data.hingeSeries || 1,
    data.units || [],
    isOrder,
    isOrder ? data.dateOrdered : ""
  );

  const orderedUnits = sortUnits(data.units || []);

  return (
    <Document>
      {!isProduction ? (
        <Page>
          <Header
            data={data}
            customerOrDealer="DEALER"
            fixed={false}
            title={isOrder ? "ORDER" : "QUOTE"}
            salesOrder={withSalesOrder}
            page1={true}
          />

          <Page1 data={data} poNumber={poNumber} />
        </Page>
      ) : (
        <Page wrap>
          <Header
            data={data}
            customerOrDealer={!isProduction ? "DEALER" : "Production"}
            fixed={true}
            title={isOrder ? "ORDER SUMMARY" : "QUOTE SUMMARY"}
            salesOrder={withSalesOrder}
          />

          <SummaryPage
            data={data}
            orderedUnits={orderedUnits || []}
            suggestedRetail={suggestedRetail}
            factor={factor}
            grandTotal={grandTotal}
            codOrTerms={codOrTerms}
            isCustomer={false}
            margin={0}
            salesTax={0}
            customClad={customClad}
            isProduction={isProduction}
            hidePricing={isProduction ? "Hide All Pricing" : ""}
          />
        </Page>
      )}

      {orderedUnits && orderedUnits.length
        ? orderedUnits.map((door, index) => (
            <>
              <Page key={index}>
                <Header
                  data={data}
                  customerOrDealer={!isProduction ? "DEALER" : "Production"}
                  fixed={false}
                  title={isOrder ? "ORDER" : "QUOTE"}
                  salesOrder={withSalesOrder}
                />

                <UnitDetails
                  form={data}
                  series={series}
                  door={door}
                  index={index}
                  bifoldSeries2={bifoldSeries2}
                  isProduction={isProduction}
                  isCustomer={false}
                />

                <View style={styles.dealerSignStyle}>
                  <Text style={{ color: "white", fontSize: 10 }}>
                    {signline}
                  </Text>
                  <View style={{ position: "relative" }}>
                    <Text style={styles.dateSigned}>{dateSigned}</Text>
                  </View>
                </View>
              </Page>

              {door.doorType.includes("Corner") ? (
                <>
                  <DrawingsCorner
                    isDealer={true}
                    unit={letters[index]}
                    form={data}
                    item={door}
                    salesOrder={withSalesOrder}
                    isProduction={isProduction}
                  />

                  <DrawingsCorner2
                    isDealer={true}
                    unit={letters[index]}
                    form={data}
                    item={door}
                    salesOrder={withSalesOrder}
                    isProduction={isProduction}
                  />
                </>
              ) : (
                <DealerDrawings
                  isDealer={true}
                  unit={letters[index]}
                  form={data}
                  item={door}
                  salesOrder={withSalesOrder}
                  isProduction={isProduction}
                />
              )}

              {isProduction &&
              cutsheetData &&
              cutsheetData.length &&
              door.doorType.includes("Corner") ? (
                <>
                  <Page wrap>
                    <CutSheets
                      unit={door}
                      dealer={data.dealer}
                      salesOrder={data.salesOrder || ""}
                      poNumber={data.poNumber || ""}
                      totalItems={orderedUnits.length}
                      unitNumber={index + 1}
                      cutsheetData={cutsheetData[index]}
                      cornerSide="left"
                    />
                  </Page>
                  <Page wrap>
                    <CutSheets
                      unit={door}
                      dealer={data.dealer}
                      salesOrder={data.salesOrder || ""}
                      poNumber={data.poNumber || ""}
                      totalItems={orderedUnits.length}
                      unitNumber={index + 1}
                      cutsheetData={cutsheetData[index]}
                      cornerSide="right"
                    />
                  </Page>
                </>
              ) : isProduction && cutsheetData && cutsheetData.length ? (
                <Page wrap>
                  <CutSheets
                    unit={door}
                    dealer={data.dealer}
                    salesOrder={data.salesOrder || ""}
                    poNumber={data.poNumber || ""}
                    totalItems={orderedUnits.length}
                    unitNumber={index + 1}
                    cutsheetData={cutsheetData[index]}
                  />
                </Page>
              ) : null}
            </>
          ))
        : ""}

      {isProduction ? (
        <Page>
          <Header
            data={data}
            customerOrDealer="Production"
            fixed={false}
            title={isOrder ? "ORDER" : "QUOTE"}
            salesOrder={withSalesOrder}
          />

          <Page1
            region={region}
            data={data}
            poNumber={poNumber}
            isProduction={true}
          />
        </Page>
      ) : (
        <>
          <Page wrap>
            <Header
              data={data}
              customerOrDealer={!isProduction ? "DEALER" : "Production"}
              fixed={true}
              title={isOrder ? "ORDER SUMMARY" : "QUOTE SUMMARY"}
              salesOrder={withSalesOrder}
            />

            <SummaryPage
              data={data}
              orderedUnits={orderedUnits || []}
              suggestedRetail={suggestedRetail}
              factor={factor}
              grandTotal={grandTotal}
              codOrTerms={codOrTerms}
              isCustomer={false}
              margin={0}
              salesTax={0}
              customClad={customClad}
              isProduction={isProduction}
              hidePricing={isProduction ? "Hide All Pricing" : ""}
              dealerTax={dealerTax || 0}
            />
          </Page>

          <Page>
            <Header
              data={data}
              customerOrDealer="DEALER"
              fixed={false}
              title="DISCLOSURES"
              salesOrder={withSalesOrder}
            />

            <Disclosures isDealer={true} />

            <View style={styles.dealerSignStyle2}>
              <Text style={{ color: "white", fontSize: 10 }}>{signline}</Text>
              <View style={{ position: "relative" }}>
                <Text style={styles.dateSigned}>{dateSigned}</Text>
              </View>
            </View>
          </Page>
        </>
      )}
    </Document>
  );
};

export default DealerCost;
