import api from "../api/api";

async function findTitle24(
  doortype: string,
  material: string,
  glass?: string,
  i89?: string,
  stiles?: string,
  stileCategory?: string,
  liteConfig?: string,
  breatherTubes?: string,
  wood?: string,
  addScreen?: string,
  swingDirection?: string,
  sill?: string,
  addDL?: string,
  exactConfig?: string,
) {
  const title24ParamsInCommon = {
    material,
    wood,
    glass,
    i89,
    stiles,
    liteConfig,
    breatherTubes,
    stileCategory,
    addScreen,
  };

  if (
    doortype.includes("Bi-fold") ||
    (doortype === "Fixed Panel" && exactConfig === "Fixed Bi-fold")
  ) {
    const res = await api.get("/title24", {
      params: {
        ...title24ParamsInCommon,
        sill,
        addDL,
      },
    });

    if (res.data.error) return "Error";

    return res.data;
  } else if (
    doortype === "Swing" ||
    doortype === "Pivot" ||
    (doortype === "Fixed Panel" &&
      (exactConfig === "Fixed Outswing" || exactConfig === "Fixed Inswing"))
  ) {
    const swangD =
      doortype === "Pivot"
        ? "Inswing"
        : doortype === "Swing"
          ? swingDirection
          : exactConfig?.split(" ")[1];

    const stilesRails =
      doortype !== "Swing" && stileCategory === "Slimline"
        ? '2-1/2" Stiles and Rails with 3-7/16" Lock Stile'
        : stiles;

    const liteConfiguration =
      addDL === "Add Intermediate Rail" ? '1 1/8"' : liteConfig;

    const res = await api.get("/title24Swings", {
      params: {
        ...title24ParamsInCommon,
        swingDirection: swangD,
        stiles: stilesRails,
        addScreen,
        liteConfig: liteConfiguration,
      },
    });

    if (res.data.error) return "Error";

    return res.data;
  } else if (
    doortype === "Slider" ||
    (doortype === "Fixed Panel" && exactConfig === "Fixed Slider")
  ) {
    const stilesUsed =
      doortype === "Slider"
        ? stiles
        : !stileCategory?.includes("Slimline")
          ? stiles
          : '2-1/2" Stiles and Top Rails with 3-7/16" Lock Stile and Bottom Rails';

    const res = await api.get("/title24Sliders", {
      params: {
        ...title24ParamsInCommon,
        stiles: stilesUsed,
        addScreen,
      },
    });

    if (res.data.error) return "Error";

    return res.data;
  }

  return { ufactor: "0.53", shgc: "0.53" };
}

export default findTitle24;
